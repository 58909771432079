exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kooperationen-tsx": () => import("./../../../src/pages/kooperationen.tsx" /* webpackChunkName: "component---src-pages-kooperationen-tsx" */),
  "component---src-pages-lesenswertes-tsx": () => import("./../../../src/pages/lesenswertes.tsx" /* webpackChunkName: "component---src-pages-lesenswertes-tsx" */),
  "component---src-pages-rechtsanwalt-tsx": () => import("./../../../src/pages/rechtsanwalt.tsx" /* webpackChunkName: "component---src-pages-rechtsanwalt-tsx" */),
  "component---src-pages-rechtsgebiete-erbrecht-tsx": () => import("./../../../src/pages/rechtsgebiete/erbrecht.tsx" /* webpackChunkName: "component---src-pages-rechtsgebiete-erbrecht-tsx" */),
  "component---src-pages-rechtsgebiete-familienrecht-tsx": () => import("./../../../src/pages/rechtsgebiete/familienrecht.tsx" /* webpackChunkName: "component---src-pages-rechtsgebiete-familienrecht-tsx" */),
  "component---src-pages-rechtsgebiete-index-tsx": () => import("./../../../src/pages/rechtsgebiete/index.tsx" /* webpackChunkName: "component---src-pages-rechtsgebiete-index-tsx" */),
  "component---src-pages-rechtsgebiete-inkasso-tsx": () => import("./../../../src/pages/rechtsgebiete/inkasso.tsx" /* webpackChunkName: "component---src-pages-rechtsgebiete-inkasso-tsx" */),
  "component---src-pages-rechtsgebiete-mietrecht-tsx": () => import("./../../../src/pages/rechtsgebiete/mietrecht.tsx" /* webpackChunkName: "component---src-pages-rechtsgebiete-mietrecht-tsx" */),
  "component---src-pages-rechtsgebiete-strafrecht-tsx": () => import("./../../../src/pages/rechtsgebiete/strafrecht.tsx" /* webpackChunkName: "component---src-pages-rechtsgebiete-strafrecht-tsx" */),
  "component---src-pages-rechtsgebiete-verkehrsrecht-tsx": () => import("./../../../src/pages/rechtsgebiete/verkehrsrecht.tsx" /* webpackChunkName: "component---src-pages-rechtsgebiete-verkehrsrecht-tsx" */)
}

